<template>
  <div class="float-right">
    <div class="d-flex align-items-center text-primary" style="height: 2rem">
      <b-link 
        :to="itemTypeRoute" 
        class="ml-1"
      >
        <b-badge
          v-b-tooltip.hover
          pill
          variant="primary"
          class="text-capitalize mb-0 ml-1 mt-75 check-button"
        >
          {{ isFullScreen ? $t('common.full-screen') : $t('common.view-all') }}
        </b-badge>
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewAllButton',
  props: {
    itemType: { type: String, required: true },
    type: { type: String },
    isFullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    itemTypeRoute() {
      if (this.itemType === 'community-members') {
        return { name: this.itemType, params: { type: this.type } };
      }
      if (this.itemType === 'entity') {
        return { name: this.itemType, params: { typeKey: this.type } };
      }
      if (this.itemType === 'people') {
        return { name: this.itemType, params: { typeKey: this.type } };
      }
      if (this.itemType === 'events' && this.type) {
        return { name: this.itemType, query: { display: this.type } };
      }
      if (this.itemType === 'challenges' && this.type) {
        return { name: this.itemType, query: { display: this.type } };
      }
      return { name: this.itemType };
    },
  },
  methods: {
    location() {
      return this.$router.push({ name: this.itemType });
    },
  },
};
</script>
<style scoped>
#search-tooltip {
  cursor: pointer;
}
.create-item {
  cursor: pointer;
}
</style>
